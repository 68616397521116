import React from 'react'
import Layout from '../components/layout'
import BlogRoll from '../components/blog-roll'
import Seo from '../components/SEO'


import '../ui/blog.css'


const post = {
  title: "Le blog le plus moussant, bio et artisanal d'internet",
  description: 'On parle de bombe de bain et d\'artisanat, tout ça dans une humeur moussante',
  image: 'https://archimouss.ch/assets/images/logo/big.png'
}



const Blog = () => {
  return (
    <>
      <Seo title={post.title} description={post.description} image={post.image}/>
      <Layout>
          <div className='main'>
              <div className='main-content'>
                  <div className='main-blog'>
                      <div className="blog-content">
                          <h1 className='title-blog-roll'>Le blog le plus moussant d'internet</h1>
                          <BlogRoll></BlogRoll>
                      </div>
                  </div>
              </div>
          </div>
      </Layout>
    </>
   
  )
}

export default Blog