import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

import '../ui/blog-roll.css'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="container-blog">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="blog" key={post.id}>

                <h2 className="title">
                  <Link
                    to={post.frontmatter.path}
                  >
                    {post.frontmatter.title}
                  </Link>
                </h2>
                <p className="date">
                  {post.frontmatter.date} - Bruno Santos Lopes
                </p>
              {
                post.frontmatter.thumbnail ? 
                      <img width="200px" height="200px" src={post.frontmatter.thumbnail} alt={post.frontmatter.title} title={post.frontmatter.title} />
                    : ''
                }

                

               
                
               
                <p>
                  {post.frontmatter.introduction}
                </p>
                <div className='read-next'>
                  <Link className="accent-button" to={post.frontmatter.path}>
                    Lire la suite →
                  </Link>
                </div>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const query = () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              id
              frontmatter {
                path
                title
                date(formatString: "MMMM DD, YYYY")
                introduction
                thumbnail
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)

export default query